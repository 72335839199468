import "Colors.css";
const HEADING_CSS = "text-sm leading-5 font-medium light_grayish_navy";
const SUB_HEADING_CSS = "text-sm leading-4 font-normal sapphire";
export const GeneralInfoView = ({ patientDetails }) => {
  console.log("Patient details are", patientDetails);
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Prefix</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.prefix}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>First Name</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.firstName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Middle Name</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.middleName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Last Name</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.lastName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Suffix</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.suffix}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Email</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.userName}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Date of Birth</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.DOB}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Gender</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.gender}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Phone Type</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.phoneType}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Phone Number</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.phoneNumber}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Address Line 1</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.address1}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Address Line 2</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.address2}</span>
      </div>

      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>City</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.city}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>State</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.state}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Country</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.country}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>ZIP Code</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.zipCode}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Membership</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.memberShipPlan}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Patient ID</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.cognitoId}</span>
      </div>
      <div className="flex flex-col gap-2">
        <span className={HEADING_CSS}>Patient ID</span>
        <span className={SUB_HEADING_CSS}>{patientDetails.cognitoId}</span>
      </div>
    </div>
  );
};
