import Axios from "Services/Auth/AxiosInterceptors";
/**
 * @description  An API for getting Patients
 * @param {Number} page Current page number
 * @returns
 */
export const GetPatients = async (page, recordsPerPage = 50) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/patient/get?page=${page}&perPage=${recordsPerPage}`
  );
  console.log("[GetPatients][API_RESPONSE] - ", result);
  return result;
};

/**
 * @description  An API for getting Patient Medications
 * @param {Number} page Current  page number
 * @param {String} id The Id of the patient
 * @returns
 */
export const GetPatientMedications = async (id, page, recordsPerPage = 8) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/patient/medications/${id}?page=${page}&perPage=${recordsPerPage}`
  );
  console.log("[GetPatientMedications][API_RESPONSE] - ", result);
  return result;
};
export const GetMedicalHistory = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/admin/patient/medicalhistory/${id}`);
  console.log('[GetMedicalHistory][API_RESPONSE] - ',result);
  return result;
};
/**
 /**
 * @description  An API for getting Patient Visit History
 * @param {Number} page Current  page number
 * @param {String} id The Id of the patient
 * @returns
 */
export const GetVisitHistory = async (id, page = 1, recordsPerPage = 20) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/patient/visit/history/${id}?page=${page}&perPage=${recordsPerPage}`
  );
  console.log("[GetVisitHistory][API_RESPONSE] - ", result);
  return result;
};

export const GetVisitOrders = async (
  id,
  visitId,
  pageNumber = 1,
  recordsPerPage = 5
) => {
  const {
    data: { result },
  } = await Axios.post(
    `/admin/patient/visit/orders/${id}?page=${pageNumber}&perPage=${recordsPerPage}`,
    visitId
  );
  console.log("[GetVisitOrders][API_RESPONSE] - ", result);
  return result;
};

/**
 * @description An API for getting notes of the Pateint provided by doctor
 * @param {String} id The id of the patient
 * @param {String} visitId The visit id of the patient chec up woth the Doctor
 * @returns {Object} Containing information about the patient noted
 */
export const GetVisitNotes = async (id, visitId) => {
  const {
    data: { result },
  } = await Axios.post(`/admin/patient/visit/notes/${id}`, visitId);
  console.log("[GetVisitNotes][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description An API for getting patient feedback
 * @param {String} id The id of the patient
 * @param {String} visitId The visit id of the patient chec up woth the Doctor
 * @returns
 */
export const GetVisitFeedback = async (id, visitId) => {
  const {
    data: { result },
  } = await Axios.post(`/admin/patient/visit/feedback/${id}`, visitId);
  console.log("[GetVisitFeedback][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description API for uploading patient files
 * @param {Sting} id The id of the patient
 * @param {Object} payload Object containing an array of patient files
 * Payload Example {
 * "otherDocuments":["5511144445","0510515110","1150510060"]
 * }
 * @returns
 */
export const PostPatientFiles = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.post(`/admin/patient/otherdocuments/add/${id}`, payload);
  console.log("[PostPatientFiles][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description API for updating patient GeneralInfo
 * @param {String} id The id of the patient
 * @param {Object} payload  Object containing updated General Information of the patient
 * Payload Example {
     "prefix": "",
    "firstName": "Eren",
    "middleName": "",
    "lastName": "Yeager",
    "suffix": "",
    "dateOfBirth": "1998-09-16",
    "gender": "Male",
    "addressLine1": "Qasimabad, Hyderabad",
    "addressLine2": "Pakistan",
    "state": "61812322269777001fd2e9a8",
    "country": "Pakistan",
    "city": "Hyderabad",
    "zipCode": "71000",
    "primaryPhone": "4442223333",
    "primaryPhoneType": "Cell"
 * }
 * @returns
 */
export const PatientUpdate = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.post(`/admin/patient/general/update/${id}`, payload);
  console.log("[PatientUpdate][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description API for getting Patient Payment Details
 * @param {String} id The id of the patient
 * @param {Number} page Current  page number
 * @returns
 */
export const GetPaymentDetails = async (id, page, recordsPerPage = 10) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/patient/invoicelogs/${id}?page=${page}&perPage=${recordsPerPage}`
  );
  console.log("[GetPaymentDetails][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description An API for getting patients access logs
 * @param {Number} page Current  page number
 * @param {String} id The Id of the patient
 * @returns
 */
export const GetAccessLogs = async (id, page, recordsPerPage = 8) => {
  const {
    data: { result },
  } = await Axios.get(
    `/admin/accesslogs/patient/${id}?page=${page}&perPage=${recordsPerPage}`
  );
  console.log("[GetAccessLogs_Patient][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description  An API for activating the patient
 * @param {String} email of the patient
 * @returns
 */
export const PatientActivate = async (email) => {
  return await Axios.post(`/admin/patient/activate`, email);
};
/**
 * @description  An API for deactivate the patient
 * @param {String} email of the patient
 * @returns
 */
export const PatientDeactivate = async (email) => {
  return await Axios.post(`/admin/patient/deactivate`, email);
};
/**
 * @description An API for getting the patient docs
 * @param {Strind} id of the patient
 * @returns
 */
export const GetPatientDocuments = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`admin/patient/otherdocuments/get/${id}`);
  console.log("[GetPatientDocuments][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description  An API for refunding
 * @param {String} id of the invoice log
 * @param {Object} payload total amount to be refunded
 * @returns
 */
export const PaymentRefund = async (id, payload) => {
  const {
    data: { result },
  } = await Axios.post(`/membership/invoice/refund/${id}`, payload);
  console.log("[PaymentRefund][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description  An API for getting the patient details
 * @param {String} id of the patient
 * @returns the patient details
 */
export const GetPatientById = async (id) => {
  const {
    data: { result },
  } = await Axios.get(`/admin/patient/get/${id}`);
  console.log("[GetPatientById][API_RESPONSE] - ", result);
  return result;
};
/**
 * @description  An API for getting the patient offer refund
 * @returns the patient refund details
 */
export const GetPatientsOfferRefunds = async () => {
  const {
    data: { result },
  } = await Axios.get(`/admin/offer/refunds/total`);
  console.log("[GetPatientsOfferRefunds][API_RESPONSE] - ", result);
  return result;
};